<template>
  <div>
    <el-container style="display: block;">
      <NavBar></NavBar>
      <el-container>
        <SubMenu></SubMenu>
        <el-container>
          <el-main>
            <router-view/>
          </el-main>
        </el-container>
      </el-container>
      <!-- <el-footer>Footer11</el-footer> -->
    </el-container>
  </div>
</template>

<script>
import NavBar from '../layout/components/NavBar.vue';
import SubMenu from '../layout/components/SubMenu.vue';
export default {
  name: '',
  components:{
    NavBar,
    SubMenu
  },
  data() {
    return {
      headerLeft: 120,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-main{
  background:#fdfdfd;
}
</style>
