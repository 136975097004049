import $api from '@/api/index';
let cacheReqPerm = null;

export default {
    namespaced: true,
    state: {
        baseSetting: {
            logo: '',
            name: ''
        },
    },
    getters: {
        
    },
    mutations: {
        setRoutes(state, routes) {
            state.routes = routes
        },
        setBaseSetting(state, option) {
            state.baseSetting.logo = option.logo;
            state.baseSetting.name = option.name;
        },
        // 获取用户数据
        setInit(state, res) {
            state.user = res?.user;
            state.copyright = "<p>Copyright 2020-2022 技术有限公司版权所有<br/> <br/>网站备案号/许可证号：</p>"
            state.admin_status = res?.admin_status
            state.admin = res?.admin||{}
        },
    },
    actions: {
        // 商城初始化数据、权限、用户、修改密码等链接
        getInit({
            dispatch
        }, { to, forceUpdate }) {
            if (!cacheReqPerm||!forceUpdate) { // 没有缓存或者强制刷新
                cacheReqPerm = new Promise((resolve, reject) => {
                    $api.shopApi.getInit()
                        .then(res => {
                            resolve(res)
                        }).catch(err => {
                            reject(err)
                        }).finally(() => {
                            cacheReqPerm = null;
                        })
                })
            }
           
            cacheReqPerm.then(res=> dispatch('setInit',{response:res,toPath:to}))

            return cacheReqPerm
        },
        // 初始化店铺设置
        setInit({commit},{response,toPath}){
            if (response.code === 1) {

                commit('setInit', response.data);

                commit('menu/refreshMenu', toPath, {
                    root: true
                })
                commit('setBaseSetting', response.data.settings || {});
            } else {
                commit('setInit', null);
            }
        },
    }
};
 