export default {
    title: '行程',
    name: 'culture',
    path: '/culture/index',
    show: true,
    icon: 'icon-zuocedaohang-shouye1',
    perm: '',
    zIndex: 3,
    children: {
        culture: {
            title: '行程',
            name: 'culture',
            path: '/culture/index',
            icon: "icon-zuocedaohang-shouye1",
            perm:'',
            show: ($route) =>
                    $route?.path === "/culture/index"
        },
    }
}
