import axios from './axios.config.js'
import store from '@/store'

// 请求配置对象
let config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Client-Type': 50, // 50  后台
        'Session-Id': '',
    },
}

let request = {}
/**
 *  post 请求
 * @param apiPath 路由
 * @param params 参数
 */
request.post = (apiPath = '', params = {}, requestConfig = {}) => {
    let url = apiPath
    // 处理请求头， 添加Session-Id
    config.headers['Session-Id'] = store.state?.account?.sessionId
    config.headers['Client-Type'] = requestConfig?.clientType || config.headers['Client-Type']
    return new Promise((resolve, reject) => {
        axios.post(url, params, config).then((result) => {
            resolve(result)
        }).catch((res) => {
            reject(res)
        })
    })
}

/**
 *  get 请求
 * @param apiPath 路由
 * @param params 参数
 */
request.get = (apiPath = '', params = {}, requestConfig = {}) => {
    let url = apiPath;
    
    if(process.env.NODE_ENV === 'development'){
        // 路径拼接参数
        // 这里需要去手动拼接url,所以直接使用了axios里面辅助方法的buildURL,
        // buildURL就是实现了url和params的拼接
        // let curl = buildURL(url, params).replace('?', '&')
        // console.log(curl);
        // params = {}
    }

    // 处理请求头， 添加Session-Id
    config.headers['Session-Id'] = store.state?.account?.sessionId
    config.headers['Client-Type'] = requestConfig?.clientType || config.headers['Client-Type']

    return new Promise((resolve, reject) => {
        axios.get(url, { params, ...config })
            .then((result) => {
                resolve(result)
            })
            .catch((res) => {
                reject(res)
            })
    })
}

export default request
