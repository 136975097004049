export function samePath(path1, path2) { //path1路由配置项，path2访问的路由
  if (!/[:(]/.test(path1 + path2)) { //非动态路由
      return path1 === path2
  } else if (/[:(]/.test(path2)) { //path2为动态路由规则
      let tmp = path1;
      path1 = path2;
      path2 = tmp;
  }
  path1 = path1.split('/')
  path2 = path2.split('/')
  let tmp1 = '';
  let tmp2 = '';
  let same = false
  if (path1.length == path2.length) {
      for (let i = 0; i < path1.length; i++) {
          tmp1 = path1[i];
          tmp2 = path2[i];
          if (tmp1 == tmp2) {
              same = true;
              continue;
          }
          if (/^:/.test(tmp1)) { // 校验 /path/:pathData/....
              same = true;
              continue;
          } else if (tmp1.indexOf('(') > -1) { // 校验 /path/path(.*)/....
              let start = tmp1.split('(')[0];
              same = tmp2.indexOf(start) == 0;
              if (same) {
                  continue;
              } else {
                  return false;
              }
          } else {
              return false;
          }
      }
      return same
  } else {
      return false
  }
}