<template>
  <el-container>
    <router-view></router-view>
  </el-container>
</template>

<script>

export default {
    data() {
        return {
          setting: {},
        }
    },
    created() {
        
    },
    computed: {
        
    },
    mounted() {
        
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>


</style>
