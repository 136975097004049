import { menuTraverser } from '../assets/js/traverser.js'
let paths = require.context('./', false, /^((?!Plugin).)*\.js$/);//获取当前全部js文件
let menus = {};//各个模块的路由配置集合
let pageIndex = 0

paths.keys().forEach(item => {
    let def = paths(item).default;
    menuTraverser(def, res => {
        if (typeof res == 'object' && !res._pageId) {
            res._pageId = '_pageId' + new Date().getTime() + '_' + pageIndex++
        }
    })
    if (def) {
        menus[item.replace(/(\.\/)|(\.js)/g,'')]=def
    }
});

export default menus