/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '行程',
         path: '/culture/index',
         perm: '',
         component: Layout,
         meta: {module: 'culture'},
         children: [
             {
                 name: '行程',
                 path: '/culture/index',
                 perm: '',
                 meta: {module: 'culture'},
                 show:false,
                 component: () => import('@/views/culture/index.vue'),
             },
             {
                name: '行程详情',
                path: '/culture/CultureDetail',
                perm: '',
                meta: {module: 'culture'},
                show:false,
                component: () => import('@/views/culture/CultureDetail.vue'),
            }
         ],
     }
 ]