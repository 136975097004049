export default {
  title: '会员',
  name: 'member',
  path: '/member/index',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 2,
  children: {
    member: {
        title: '会员列表',
        name: 'member',
        path: '/member/index',
        icon: "icon-zuocedaohang-shouye1",
        perm:'',
        show: ($route) =>
                $route?.path === "/member/index"
    },
    mcate: {
      title: '职位',
      name: 'member',
      path: '/member/cate',
      icon: "icon-zuocedaohang-shouye1",
      perm:'',
      show: ($route) =>
              $route?.path === "/member/cate"
    },
    industry: {
        title: '公司行业',
        name: 'member',
        path: '/member/industry',
        icon: "icon-zuocedaohang-shouye1",
        perm:'',
        show: ($route) =>
                $route?.path === "/member/industry"
    }
  }
}
