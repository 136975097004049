export default {
  title: '反馈',
  name: 'feedback',
  path: '/feedback/index',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 4,
  children: {
      diypage: {
          title: '反馈',
          name: 'feedback',
          path: '/feedback/index',
          icon: "icon-zuocedaohang-shouye1",
          perm:'',
          show: ($route) =>
                  $route?.path === "/feedback/index"
      }
  }
}
