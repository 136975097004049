/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '反馈',
         path: '/feedback/index',
         perm: '',
         component: Layout,
         meta: {module: 'feedback'},
         children: [
             {
                 name: '反馈',
                 path: '/feedback/index',
                 perm: '',
                 meta: {module: 'feedback'},
                 show:false,
                 component: () => import('@/views/feedback/index.vue'),
             },
         ],
     }
 ]