import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from './router/index'
import store from './store/index'
import {VueUeditorWrap} from 'vue-ueditor-wrap'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as echarts from 'echarts';

const app = createApp(App);
app.use(ElementPlus, {
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(echarts).use(router).use(VueUeditorWrap).mount('#app');
