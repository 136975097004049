var config = {
	base_url: '/',
	route: 'history',
};

try {
	exports.config = config;
} catch (e) {
	console.log(e);
}
