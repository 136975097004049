/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '设置',
         path: '/setting/index',
         perm: '',
         component: Layout,
         meta: {module: 'setting'},
         children: [
             {
                 name: '设置',
                 path: '/setting/index/index',
                 perm: '',
                 meta: {module: 'setting'},
                 show:false,
                 component: () => import('@/views/setting/index.vue'),
             },
         ],
     }
 ]