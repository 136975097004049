<template>
  <router-view/>
</template>

<script>
import {
    mapState,
    mapGetters
} from 'vuex'

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState('layout', {
        routerEntering: state => state.routerEntering,
    }),
    ...mapState('config', {
        user: state => state.user,
    }),
    ...mapState({
        requestInfo: state => state.requestInfo
    }),
    ...mapGetters({
        getRequestInfo: 'getRequestInfo'
    }),
    ...mapState('account', {
        shopId: state => state.shop.shopId,
    }),
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,body{
  padding:0;
  margin: 0;
}
a{
  text-decoration: unset;
}
</style>
