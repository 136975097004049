export const cultureApi = {
  set: { // 获取session-id
    method: 'post',
    api: 'user/culture/set'
  },
  getList: { // 获取session-id
    method: 'post',
    api: 'user/culture/list'
  },
  get: { // 获取session-id
    method: 'post',
    api: 'user/culture/get'
  },
  del: { // 获取session-id
    method: 'post',
    api: 'user/culture/del'
  },
}