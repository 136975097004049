import Vuex from 'vuex';
import createPersistedState  from 'vuex-persistedstate'

let paths = require.context('./', false, /\/.*\.js$/);//获取当前全部路由
let modules = {};//各个模块的集合
let moduleName = [];
paths.keys().map(item => {//获取本级目录下的全部配置参数
    if (item != './index.js') {
        moduleName.push(item.replace(/(\.\/|\.js)/g, ''));
        return paths(item).default;
    }
    return '';
}).filter(item => item).forEach((item, index) => {
    modules[moduleName[index]] = item;
});

export default new Vuex.Store({
    modules: {
        ...modules,//文件名即为模块名
    },
    state: {
        requestInfo: {
            error: 0,
            message: ''
        },
        curRoute: {
            path: '',
            query: {}
        }
    },
    mutations: {
        setRequestInfo(state, info) {
            state.requestInfo = info;
        },
        setCurRoute(state, route) {
            state.curRoute = route;
        }
    },
    actions: {},
    getters: {
        getRequestInfo(state) {
            return {
                routeInfo: state.curRoute,
                ...state.requestInfo
            }
        }
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer: state => {
                return {
                    // 要保存的数据
                    config: state.config,
                    setting: state.setting,
                    account: state.account, // 缓存账户信息，防止刷新页面丢失shopId
                }
            }
        })
    ]
});