/**
 * 实现类似axios的请求方法
 */
import store from '../../store'
import api from '../index'
/**
 * 专门用于处理sessionId的请求与处理
 */
let throttle
const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId')
     //  过滤存储undefined
     if(sessionId=='undefined') {
        sessionId = ''
    }
    
    //所属店铺数据不存在就重新请求
    if (!sessionId) {
        // 判断是否正在请求sessionId,''或undefined是没有正在请求
        if (throttle) return throttle;
        throttle = new Promise((resolve, reject) => {
            api.aloneApi.getSessionId().then(res => {
                // 请求sessionId完毕
                if (res.code) {
                    reject('获取sessionId失败')
                } else {
                    store.commit('account/setSessionId', res.data.sessionId)
                    resolve(res.data.sessionId);
                    throttle = null
                }
            }).catch((err) => {
                reject('获取sessionId失败',err);
            })
        })
        return throttle
    } else {
        return Promise.resolve(sessionId)
    }
}

export default getSessionId
