export const feedbackApi = {
  getList: { // 获取session-id
    method: 'post',
    api: 'user/feedback/getList'
  },
  checkfeedback: { // 获取session-id
    method: 'post',
    api: 'user/feedback/checkfeedback'
  },
  delfeedback: { // 获取session-id
    method: 'post',
    api: 'user/feedback/del'
  },
}