import settings from '@/api/config/settings';
// 本地测试用
let sessionId =
    process.env.NODE_ENV === 'development'
        ? settings.config.user_session?.session_id || ''
        : ''
export default {
    namespaced: true,
    state: {
        sessionId: localStorage.getItem('sessionId') || sessionId,
    },
    getters: {},
    mutations: {
        setSessionId(state, sessionId = '') {
            state.sessionId = sessionId
            localStorage.setItem('sessionId', sessionId)
        },
    }
}