import { createRouter, createWebHistory } from 'vue-router'
import permMapper from './permit/permMap.js'
import { afterEach,beforeGuard } from './guard/index'
import store from '@/store'

let paths = require.context('./', false, /\/.*\.js$/)

let routes = [] //各个模块的路由配置集合
paths.keys()
      .map((item) => {
          //获取本级目录下的全部配置参数
          return paths(item).default
      })
      .filter((item) => item)
      .forEach((item) => {
          routes = routes.concat(item)
      })
//在router4中使⽤createRouter替代了new VueRouter;
const router = createRouter({ 
 history: createWebHistory(process.env.BASE_URL),
 routes
 //在此处引⼊所有页⾯的路由配置数组routes;
})
permMapper.init(routes)
store.commit('config/setRoutes', routes)
router.afterEach(afterEach)
router.beforeEach(async (to, from, next) => {
    try {
        /* 检测路径 */
        await beforeGuard.rediectPath(to, from, next)
        /* 调整路由参数 */
        await beforeGuard.parsePath(to, from, next)
        /* 检测登录状态 */
        await beforeGuard.checkLogin(to, from, next)
        /* 检测权限 */
        await beforeGuard.checkPerm({ to, from, next })
    
    } catch (error) {
        console.log(error)
    }
})

export default router