/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '会员',
         path: '/member/index',
         perm: '',
         component: Layout,
         meta: {module: 'member'},
         children: [
             {
                 name: '会员',
                 path: '/member/index',
                 perm: '',
                 meta: {module: 'member'},
                 show:false,
                 component: () => import('@/views/member/index.vue'),
             },
             {
                name: '添加会员',
                path: '/member/add',
                perm: '',
                meta: {module: 'member'},
                show:false,
                component: () => import('@/views/member/add.vue'),
            },
             {
                name: '详情',
                path: '/member/detail',
                perm: '',
                meta: {module: 'member'},
                show:false,
                component: () => import('@/views/member/detail.vue'),
            },
             {
                name: '职位',
                path: '/member/cate',
                perm: '',
                meta: {module: 'member'},
                show:false,
                component: () => import('@/views/member/cate.vue'),
            },
            {
               name: '行业',
               path: '/member/industry',
               perm: '',
               meta: {module: 'member'},
               show:false,
               component: () => import('@/views/member/industry.vue'),
            }
         ],
     }
 ]